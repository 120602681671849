//画像格納ディレクトリ
$imgDir: '/assets/images/';

//最小横幅
$minPcContentWidth: 1200px;

//カラー設定
$PRIMARY_050 : #f9edcf;
$PRIMARY_100: #f4db95;
$PRIMARY_200: #eeca44;
$PRIMARY_250: #dbba3e;
$PRIMARY_300: #c8aa39;
$PRIMARY_350: #b69a34;
$PRIMARY_400: #a48c2f;
$PRIMARY_450: #927c2a;
$PRIMARY_500: #816e25;
$PRIMARY_550: #716020;
$PRIMARY_600: #60521b;
$PRIMARY_700: #514517;
$PRIMARY_750: #413713;
$PRIMARY_800: #322b0e;
$PRIMARY_850: #251f0a;
$PRIMARY_900: #171307;

$SECONDARY_050: #ebedfc;
$SECONDARY_100: #d7dbfa;
$SECONDARY_200: #c3caf8;
$SECONDARY_250: #afb9f6;
$SECONDARY_300: #9ba8f4;
$SECONDARY_350: #8697f3;
$SECONDARY_400: #7086f1;
$SECONDARY_450: #5874f0;
$SECONDARY_500: #4264e6;
$SECONDARY_550: #3957c8;
$SECONDARY_600: #314bac;
$SECONDARY_700: #293f90;
$SECONDARY_750: #213375;
$SECONDARY_800: #1a275a;
$SECONDARY_850: #131c41;
$SECONDARY_900: #0c1229;

$GRAY_050: #eeeeee;
$GRAY_100: #dddddd;
$GRAY_200: #cccccc;
$GRAY_250: #bcbcbc;
$GRAY_300: #acacac;
$GRAY_350: #9d9d9d;
$GRAY_400: #8d8d8d;
$GRAY_450: #7e7e7e;
$GRAY_500: #6f6f6f;
$GRAY_550: #616161;
$GRAY_600: #535353;
$GRAY_700: #454545;
$GRAY_750: #383838;
$GRAY_800: #2b2b2b;
$GRAY_850: #1f1f1f;
$GRAY_900: #131313;

$RED_050: #fceae9;
$RED_100: #f9d4d4;
$RED_200: #f7bfbe;
$RED_250: #f5a9a7;
$RED_300: #f3918f;
$RED_350: #f17875;
$RED_400: #f05b56;
$RED_450: #e14740;
$RED_500: #c73f39;
$RED_550: #ae3732;
$RED_600: #962f2b;
$RED_700: #7d2724;
$RED_750: #66201d;
$RED_800: #4f1917;
$RED_850: #3a1210;
$RED_900: #250c0a;

$BASE_WHITE: #FFF;
$BASE_BLACK: #333;

$DANGER: #BE0123;
$DANGER_PALE: #E4ABB6;
$WARNING: #FFD335;
$WARNING_PALE: #F8EABB;
$SUCCESS: #34C759;
$SUCCESS_PALE: #BBE7C6;
$INFO: #0084F8;
$INFO_PALE: #ABD3F5;
$INFO_PALE_30: #E6F2FC;

$TEXT: #001B36;
$TEXT_PALE: #9E9E9E;

$LINK: #1a0dab; //　google,bing
$LINK_VISITED: #9E9E9E;
$LINK_HOVER: #9E9E9E;


$PRIMARY: $PRIMARY_200;
$SECONDARY: $SECONDARY_850;
$GRAY: $GRAY_600;
$RED: $RED_550;

$BG_PRIMARY: $PRIMARY_050;
$BG_SECONDARY: $RED_050;


// フォント設定
@import url('//fonts.googleapis.com/earlyaccess/notosansjp.css');
@import url('//fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');


$NotoSans-R: "NotoSansJP-Regular",sans-serif;
$NotoSans-B: "NotoSansJP-Bold",sans-serif;
$Ubuntu-R: "Ubuntu-Regular",sans-serif;
$Ubuntu-B: "Ubuntu-Bold",sans-serif;

$font-site: 'Noto Sans JP', "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

$font-jp: 'Noto Sans JP', sans-serif;
$font-en: 'Ubuntu', sans-serif;

//テキスト設定
$size-title: 16px;
$size-title-l: 18px;
$size-title-s: 14px;

$size-heading-00: 112px;
$size-heading-0: 96px;
$size-heading-01: 80px;
$size-heading-1: 64px;
$size-heading-2: 48px;
$size-heading-3: 36px;
$size-heading-4: 32px;
$size-heading-5: 24px;
$size-heading-6: 20px;


$size-text-xxl: 22px;
$size-text-xl: 20px;
$size-text-l: 18px;
$size-text: 16px;
$size-text-s: 14px;
$size-text-xs: 12px;
$size-text-xxs: 10px;
$size-text-xxxs: 9px;

$size-caption: 12px;
$size-caption-s: 10px;

// シャドウ設定
$drop-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
$base-shadow: drop-shadow(0px 0px 2px rgba(0,0,0,0.15));
$base-shadow-layer01: drop-shadow(0px 0px 2px rgba(0,0,0,0.15));
$base-shadow-layer02: drop-shadow(0px 0px 2px rgba(0,0,0,0.15));
$base-shadow-layer03: drop-shadow(0px 0px 2px rgba(0,0,0,0.15));
$base-shadow-layer04: drop-shadow(0px 2px 8px rgba(0,0,0,0.20));
$base-shadow-layer05: drop-shadow(0px 0px 2px rgba(0,0,0,0.15));