input[type="date"] {
    border: none;
    position: relative;
    padding: 0;
    width: 100%;
    background: transparent;
    box-sizing: border-box;
    outline: none;
    font-size: $size-text;
    text-align: center;
}

input[type="time"] {
  border: none;
  position: relative;
  padding: 0;
  width: 100%;
  background: transparent;
  box-sizing: border-box;
  outline: none;
  font-size: $size-text;
  text-align: center;
}

input{
  &[type="date" i]::-webkit-calendar-picker-indicator 
    {
    -webkit-appearance: none;
    display: none;
  }
  &[type="time" i]::-webkit-calendar-picker-indicator{
    -webkit-appearance: none;
    display: none;
  }
}


.startDate-day{
  font-size: $size-text-xl;
  padding: 0.25rem 0.25rem;
  cursor: pointer;
  @include sp-xs {
    font-size: $size-text-l;
  }
  @include sp-xss {
    font-size: $size-text;
  }
  &.is-today{
    position: relative;
    width: 140px;
    @include sp-xs {
      width: 120px;
    }
    &:before{
      content: '本日';
      position: absolute;
      width: 3.2rem;
      top: -0.375rem;
      right: 16px;
      color: $SECONDARY;
      font-weight: bold;
      font-size: $size-text-xxs;
      @include sp-xxs {
        font-size: $size-text-xxxs;
      }
    }
  }
}

.renewDate-edit{
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-align: center;
  background-color: $SECONDARY_050;
  color: $SECONDARY;
  padding: 0.125rem;
  border-radius: 0.25rem;
  cursor: pointer;
  margin-left: 0.25rem;
  &:hover{
    opacity: 0.7;
  }
  @include sp-m {
    width: 1.8rem;
    height: 1.8rem;
    padding: 0.25rem;
    margin-left: 0.5rem;
  }
}


.noticeDate-select-input{
  margin-left: 1rem;
  padding: 0.375rem 0.5rem;
  font-size: $size-text-l;
  border-radius: 0.25rem;
  cursor: pointer;
  border: 1px solid #DDD;
  width: 100px;
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @include sp-m {
    font-size: $size-text-l;
  }
  @include sp-xs {
    padding: 0.25rem 0.375rem;
  }
  @include sp-xxs {
    width: 90px;
    margin-left: 0.75rem;
  }
  &.is-disabled{
    color: black;
    background-color: #ccc;
    opacity: 0.5;
    cursor: unset;
  }
  span{
    font-size: $size-text-s;
    display: flex;
    align-items: center;
    padding-left: 0.125rem;
  }
}


  
.scroll-select-box{
  width: 100%;
  height: 280px;
  overflow: hidden;
  position: relative;
  padding: 0!important;
  &::before{
    position: absolute;
    top: 6.8rem;
    left: 0;
    content: "";
    width: 100%;
    height: 60px;
    background-color: #F9EDCF;
    border: 1px solid #F9EDCF;
  }
}
.scroll-select-inner{
  width: 100%;
  height: 280px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
  ul{
    width: 33%;
    height: 100%;
    overflow-y: scroll;
    scroll-snap-stop: normal;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    scroll-padding-top: 6em;
    scroll-snap-align: center;
    scroll-snap-type: y proximity;
    padding: 6rem 0 5.8rem;
    &::-webkit-scrollbar{
      display: none;
    }
    li{
      scroll-snap-align: start;
      margin-bottom: 0.25rem;
      font-size: 24px;
      text-align: center;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        font-size: 12px;
        margin-left: 0.25rem;
      }
    }
  }
  &.is-date-select{
    justify-content: center;
  }
  &.is-time-select{
    justify-content: center;
    ul{
      width: 36%;
    }
  }
}




  