// // animation
@keyframes SlideIn {
  0% {
    opacity: 0.5;/*初期状態では透明に*/
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes SlideInRight {
  0% {
    opacity: 0.5;/*初期状態では透明に*/
    transform: translateX(-3rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes popup {
  0% {
    transform: translateY(40px) scale(0.9);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scale(1.0);
  }
  80%, 100% {
    opacity: 1;
  }
}