.app-box {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0.5rem 1.5rem;
  height: 100%;
  overflow: auto;
  @include sp-xs {
    padding: 0.5rem .5rem;
  }
  @include sp-m {
    padding: 1.25rem .5rem;
  }
  &::-webkit-scrollbar{
    display:none;
  }
  &.is-modal-open{
    overflow: hidden;
  }

  .app-box-heading {
    color: $BASE_BLACK;
    font-size: $size-heading-3;
    line-height: 1.5;
    display: flex;
    align-items: center;
    padding: 0.25rem 1rem;
    @include sp-m {
      font-size: $size-heading-2;
    }
  }

  .app-box-body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app-box-button {
    width: 100%;
    padding: 1rem 1rem 0;
    display: flex;
    @include sp-m {
      padding: 1rem 2.5rem 0;
    }
    @include sp-xxs {
      padding: 0.5rem 1rem 1rem;
      margin-bottom: auto;
    }

    .button-wrap {
      color: #333;
      width: 100%;
      border: 1px solid $SECONDARY;
      background-color: $BASE_WHITE;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-right: 2rem;
      padding: 0.25rem 0.5rem 0.75rem;
      border-radius: 1rem;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
      @include sp-s {
        flex-wrap: nowrap;
        padding: 1rem 0.5rem;
      }
      @include sp-m {
        padding: 1.5rem 0.5rem;
      }
      @include sp-xss {
        margin-right: 1rem;
        padding: 0.5rem 0.5rem;
      }
      @include sp-xxs {
        margin-right: 0.5rem;
        padding: 0.5rem 0.375rem;
      }
      &:hover{
        opacity: 0.8;
      }
      &:first-child {
        background-color: $PRIMARY;
        border-color: $PRIMARY;
      }
      &:last-child {
        margin-right: 0;
      }
      &.is-disabled{
        opacity: 0.4;
        background-color: $PRIMARY_100!important;
        border-color: $PRIMARY_100!important;
      }
      .button-icon{
        width: 100%;
        line-height: 1;
        svg{
          width: 3rem;
          height: 3rem;
          @include sp-m {
            width: 4rem;
            height: 4rem;
          }
          @include sp-xss {
            width: 42px;
            height: 42px;
          }
          @include sp-xxs {
            width: 36px;
            height: 36px;
          }
        }
      }
      .button-label{
        width: 100%;
        line-height: 1.2;
        font-size: $size-text-s;
        @include sp-m {
          margin-top: 0.5rem;
          line-height: 1.4;
          font-size: $size-text-l;
        }
        @include sp-xss {
          line-height: 1.2;
          font-size: $size-text-xs;
        }
        @include sp-xxxs { //w260対応
          font-size: $size-text-xxs;
        }
      }
    }
  }

  .app-box-menu{
    position: absolute;
    top: -0.5rem;
    right: -1.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    .menu-box{
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;
      border: 1px solid $GRAY_200;
      border-radius: 0.5rem;
      padding: 0.25rem 0.25rem 0.5rem;
      color: $SECONDARY;
      background-color: $BASE_WHITE;
      cursor: pointer;
      @include sp-m {
        width: 56px;
        padding: 0.5rem 0.25rem 0.625rem;
      }
      &:hover{
        opacity: 0.8;
      }
      .menu-box-icon{
        width: 100%;
        line-height: 1;
      }
      .menu-box-label{
        width: 100%;
        font-size: $size-text-xs;
        line-height: 1;
        @include sp-m {
          margin-top: 0.125rem;
          font-size: $size-text-s;
        }
      }
    }
  }
}

.is-top-app{
  position: relative;
  .app-box-menu{
    top: 0;
    right: 0;
  }
}