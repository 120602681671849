.is-error-app{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .app-box-button {
    padding: 1rem 2rem 0.5rem;
    justify-content: center;
    margin-top: auto;
    .button-wrap {
      width: 80%;
      border: 1px solid $SECONDARY;
      background-color: $BASE_WHITE;
      margin-right: 0;
      padding: 0.25rem 0.5rem 0.75rem;
      border-radius: 3rem;
      @include sp-s {
        width: 70%;
        padding: 0.5rem 0.5rem;
      }
    }
  }
}

.error-box{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: $PRIMARY_100;
  padding: 1rem;
  border-radius: 0.5rem;
  .error-box-image{
    width: 100%;
    margin-bottom: 1rem;
    align-items: center;
    justify-content: center;
    display: none;
    img{
      width: 50%;
    }
  }
  .error-box-title{
    width: 100%;
    text-align: center;
    font-size: $size-text-l;
    margin-bottom: 1rem;
  }
  .error-box-text{
    width: 100%;
    text-align: center;
    font-size: $size-text-s;
  }

  &.is-error-message{
    background-color: #FFF;
    border: 6px solid $PRIMARY_100;
    .error-box-image{
      display: flex;
    }
  }
}