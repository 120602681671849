.is-setting-app{
  background-image: none;
  background-color: #FFF;
  @include sp-m {
    padding: 1.25rem 1rem 0.125rem;
  }
  @include sp-xs {
    padding: 0.5rem 1rem;
  }
  .app-box-menu{
    z-index: 2;
    width: 100vw;
    padding: 0.75rem 0.5rem;
    @include sp-m {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      top: -1.25rem;
      right: -1rem;
      padding: 0.5rem;
    }
    @include sp-xs {
      top: -0.5rem;
      right: -1rem;
    }
    .menu-box{
      width: auto;
      min-width: 36px;
      border: 1px solid $GRAY_200;
      background-color: #FFF;
      border-radius: 2rem;
      padding: 0.125rem 0.125rem;
      margin-right: 0.375rem;
      @include sp-m {
        min-width: 36px;
        min-height: 36px;
      }
      &:hover{
        opacity: 0.8;
      }
      &:last-child{
        margin-right: 0;
        @include sp-m {
          padding: 0.25rem 0.5rem 0.25rem 0.5rem;
        }
        @include sp-xss {
          padding: 0.125rem 0.125rem;
          min-width: 26px;
          svg{
            width: 18px;
            height: 18px;
          }
        }
      }
      .menu-box-icon{
        width: auto;
      }
      .menu-box-label{
        width: auto;
        font-size: $size-text-xxs;
        line-height: 1.15;
        @include sp-m {
          line-height: 1.1;
        }
        @include sp-xs {
          line-height: 1.05;
        }
        @include sp-xsss {
          font-size: $size-text-xxxs;
        }
        @include sp-xxs {
          font-size: $size-text-xxxs;
        }
      }
      svg{
        @include sp-m {
          width: 26px;
          height: 26px;
        }
        @include sp-xs {
          width: 20px;
          height: 20px;
        }
      }
      &.calender-menu{
        padding: 0.1rem 0.625rem 0.1rem 0.375rem;
        @include sp-m {
          padding: 0.125rem 0.625rem 0.125rem 0.375rem;
        }
        @include sp-xss {
          margin-right: 0.25rem;
          padding: 0.125rem 0.375rem 0.125rem 0.25rem;
        }
        .menu-box-icon{
          margin-right: 0.1rem;
          @include sp-xss {
            svg{
              width: 18px;
              height: 18px;
            }
          }
        }
        .menu-box-label{
          @include sp-xss {
            font-size: 9px;
          }
          @include sp-xxxs {
            font-size: 0px;
          }
        }
      }
      &.back-button-menu{
        margin-right: auto;
        padding: 0.1rem 0.5rem 0.1rem 0.25rem;
        .menu-box-icon{
          line-height: 1;
          @include sp-xss {
            svg{
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }
  .app-box-button {
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    margin-top: auto;

    .button-wrap {
      flex-wrap: nowrap;
      align-items: center;
      margin-right: 0;
      padding: 0.75rem;
      border-radius: 3rem;
      &:hover{
        opacity: 0.8;
      }

      &:first-child {
        background-color: $PRIMARY;
        border-color: $PRIMARY;
      }

      .button-icon{
        width: auto;
        margin-right: 0.25rem;
        svg{
          width: 24px;
          height: 24px;
        }
      }
      .button-label{
        width: auto;
        font-size: $size-text;
        margin-top: 0;
      }
    }
  }
}

.app-box-body{
  width: 100%;
  flex-wrap: wrap;
}


.setting-wrap{
  width: 100%;
  .setting-line{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
    border-bottom: 1px solid $GRAY_100;
    @include sp-m {
      padding: 0.625rem 0.5rem 0.625rem 0.75rem;
    }

    .setting-line-label{
      width: 34%;
      font-size: $size-text-s;
      position: relative;
      @include sp-m {
        width: 38%;
        font-size: $size-text-l;
      }
      @include sp-xs {
        width: 33%;
        font-size: $size-text-s;
      }
      @include sp-xss {
        width: 33%;
        font-size: $size-text-xs;
      }
      @include sp-xxs {
        width: 33%;
        font-size: $size-text-xs;
      }
      &:before{
        position: absolute;
        content: '●';
        top: 0.1rem;
        left: -0.8rem;
        color: $GRAY_200;
        font-size: $size-text-xxs;
        @include sp-m {
          top: 0rem;
        }
      }
    }
    .setting-line-body{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &.term-select-line{
      padding: 0.5rem 0;
      .term-select{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $SECONDARY_050;
        padding: 0.25rem;
        border-radius: 0.25rem;
        @include sp-m {
          padding: 0.375rem;
        }
        li{
          width: 100%;
          padding: 0.25rem;
          margin-right: 0.125rem;
          background-color: $SECONDARY_100;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.25rem;
          @include sp-m {
            padding: 0.375rem 0.5rem;
            border-radius: 0.25rem;
            margin-right: 0.25rem;
            font-size: $size-text-l;
          }
          @include sp-xs {
            font-size: $size-text;
          }
          &:last-child{
            margin-right: 0;
          }
          &.is-selected{
            background-color: $SECONDARY;
            color: $BASE_WHITE;
            font-weight: bold;
          }
        }
      }
    }

    &.startDate-line {
      .setting-line-label{
        &:before{
          color: $PRIMARY;
        }
      }
      .setting-line-body{ 
        .startDate-decrease,
        .startDate-increase{
          width: 1.6rem;
          height: 1.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1;
          text-align: center;
          background-color: $PRIMARY;
          color: $SECONDARY;
          padding: 0.25rem;
          border-radius: 50%;
          cursor: pointer;
          @include sp-m {
            width: 1.8rem;
            height: 1.8rem;
          }
          @include sp-xs {
            width: 1.6rem;
            height: 1.6rem;
          }
          &:hover{
            opacity: 0.7;
          }
          &.is-disabled{
            cursor: unset;
            opacity: 0.2;
            background-color: $GRAY_400;
          }
        }
        .startDate-decrease{
          margin-right: 1.75rem;
          @include sp-xs {
            margin-right: 1.05rem;
          }
          @include sp-xxs {
            margin-right: .5rem;
          }
        }
        .startDate-increase{
          margin-left: 1.75rem;
          @include sp-xs {
            margin-left: 1.05rem;
          }
          @include sp-xxs {
            margin-left: .5rem;
          }
        }
      }
    }

    &.renewDate-line {
      .setting-line-label{
        &:before{
          color: $SECONDARY;
        }
      }
      .setting-line-body{ 
        .renewDate-day{
          font-size: $size-text;
          display: flex;
          align-items: center;
          line-height: 1;
          @include sp-m {
            font-size: $size-text-l;
          }
          .small-text{
            font-size: $size-text;
            @include sp-m {
              font-size: $size-text;
            }
          }
        }
        .renewDate-until-day{
          font-size: $size-text;
          display: flex;
          align-items: center;
          line-height: 1;
          @include sp-m {
            font-size: $size-text-l;
          }
        }
      }
    }

    &.noticeDate-line{
      align-items: flex-start;
      .setting-line-label{
        &:before{
          color: $RED_400;
        }
      }
      .setting-line-body{
        &.noticeDate-wrap{
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          .noticeDate-box{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
  
            .noticeDate-label{
              font-size: $size-text-s;
              @include sp-m {
                font-size: $size-text;
              }
              @include sp-xs {
                font-size: $size-text-s;
              }
              @include sp-xxs {
                font-size: $size-text-xs;
              }
            }
            
            .noticeDate-toggle{
              display: flex;
              align-items: center;
              padding: 0.25rem;
              cursor: pointer;
              svg{
                width: 32px;
                height: 32px;
                @include sp-m {
                  width: 36px;
                  height: 36px;
                }
                @include sp-xs {
                  width: 28px;
                  height: 28px;
                }
              }
              &.is-active{
                color: $RED_400;
              }
            }
            .noticeDate-select{
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}