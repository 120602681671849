@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap');

* {
  box-sizing: border-box;
}
ul,ol,li{
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}



body {
  background: #FFF;
  color: $SECONDARY;
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  font-size: 16px;
  font-family: 'Quicksand','Noto Sans JP',"Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
    overscroll-behavior-y: none;
    text-size-adjust: 100%;
}

input{
  color: $SECONDARY;
  &[type="date" i]::-webkit-calendar-picker-indicator 
    {
    display: none;
  }
  &[type="time" i]::-webkit-calendar-picker-indicator{
    display: none;
  }
}

body:after {
  opacity: 0.12;
  width: 100%;
  height: 100%;
}

@keyframes anime_stripe_1 {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: -40px;
  }
}

.App{
  height: 100vh;
}

.main-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  .app-wrap {
    height: 100%;
    width: 100%;
    max-width: $mq-480;
    position: relative;
    overflow: hidden;
    box-sizing: content-box;
    .app-wrap-inner {
      height: 100%;
      padding: 0;
      position: relative;
      overflow: hidden;
    }
  }
}
