.tab-wrap{
  width: 100%;
  padding: 0.5rem;
}
.tab-index{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 14px;
  padding-top: 1rem;
  @include sp-s {
    padding-top: 0.25rem;
    font-size: 12px;
  }
  li{
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    height: 30px;
    padding: 0.25rem 0.25rem 1rem;
    margin-bottom: 0.5rem;
    line-height: 1.1;
    border: none;
    border-bottom: 3px solid $SECONDARY_050;
    color: $GRAY_400;
    @include sp-s {
      height: 30px;
      line-height: 1.1;
    }
    @include sp-m {
      height: 36px;
      line-height: 1.2;
    }
    &.react-tabs__tab--selected{
      outline: none;
      border-bottom: 5px solid $SECONDARY;
      font-weight: bold;
      color: $SECONDARY;
    }
  }
}

.tab-body{
  padding: 1rem;
  height: 10rem;
  overflow-y: scroll;
  @include sp-s {
    padding: 0.5rem;
    height: 9rem;
    line-height: 1.2;
  }
  @include sp-m {
    height: 11rem;
    line-height: 1.6;
  }
}