.modal-wrap{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  .modal-overlay{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 1;
  }
  .modal-contents{
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFF;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 0.5rem 0.5rem 0 0;
    border-bottom: 1px solid #DDD;

    .modal-heading{
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      font-weight: 700;
      svg{
        margin-right: 0.25rem;
      }
      @include sp-m {
        padding: 1rem 1rem;
        font-size: $size-text-l;
      }
    }
    .modal-footer{
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0.25rem 1rem;
      .app-box-button {
        width: 100%;
        padding: 0.5rem 1rem;
        display: flex;
        @include sp-s {
          padding: 0.25rem 1.5rem;
        }
        @include sp-xs {
          padding: 0.25rem .5rem;
        }
        
    
        .button-wrap {
          color: #333;
          width: 100%;
          border: 1px solid $SECONDARY;
          background-color: $BASE_WHITE;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          margin-right: 2rem;
          padding: 0.75rem 0.5rem 0.75rem;
          border-radius: 32px;
          font-weight: bold;
          text-align: center;
          cursor: pointer;
          &:hover{
            opacity: 0.8;
          }
          &:last-child {
            background-color: $PRIMARY;
            border-color: $PRIMARY;
            margin-right: 0;
          }

          @include sp-xs {
            margin-right: 1rem;
          }
    
          .button-icon{
            width: 100%;
            line-height: 1;
            svg{
              width: 48px;
              height: 48px;
            }
          }
          .button-label{
            width: 100%;
            line-height: 1.2;
            font-size: $size-text-s;
            @include sp-m {
              margin: 0;
              line-height: 1.4;
              font-size: $size-text;
            }
          }
        }
      }
    }

    .modal-body{
      width: 100%;
      min-height: 120px;
      display: flex;
      padding: 1rem 1rem;
    }
    
  }
}


.calendar-wrap{
  .calendar-box{
    position: relative;
    .modal-wrap{
      top: 0;
      left: 0;
    
      .modal-overlay{
        width: 100%;
        background-color: rgba(0,0,0,0.2);
        border-radius: 0.25rem;
      }
      .modal-contents{
        bottom: auto;
        top: 0;
        left: 0%;
        padding: 0;
        width: 50%;
        border-radius: 0.5rem;
        background-color: transparent;
        border: none;
        @include sp-xs {
          width: 54%;
        }
        
        .setDate-day{
          width: 2.1rem;
          height: 2.1rem;
          margin: 0;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $BASE_WHITE;
          border: 2px solid $BASE_WHITE;
          box-sizing:content-box;
          -moz-box-sizing: content-box;
          -webkit-box-sizing: content-box;
          border-radius: 50%;
          font-size: $size-text-xl;
          font-weight: 600;
          line-height: 1;
          @include sp-s {
            width: 2.2rem;
            height: 2.12rem;
            font-size: $size-text-xl;
          }
          @include sp-m {
            width: 2.2rem;
            height: 2.12rem;
            font-size: $size-text-xxl;
          }
          @include sp-xs {
            width: 2.1rem;
            height: 2.02rem;
            font-size: $size-text-xl;
          }
        }
        .setDate-list{
          width: 100%;
          background-color: #FFF;
          border: 1px solid $GRAY_200;
          border-radius: 0.5rem;
          margin-top: 0.25rem;
          .setDate-list-body{
            &.setDate-start,
            &.setDate-renew{
              width: 100%;
              font-size: $size-text-s;
              padding: 0.5rem 0.125rem 0.5rem 0.75rem;
              text-align: left;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              @include sp-xs {
                padding: 0.5rem 0.125rem 0.5rem 0.5rem;
              }
              .setDate-list-icon{
                line-height: 1;
                display: flex;
                align-items: center;
                svg{
                  width: 18px;
                  height: 18px;
                }
              }
            }
            &:first-child{
              border-bottom: 1px solid $GRAY_200;
            }
            &:last-child{
              border-bottom: none;
            }
          }
          .setDate-renew{
            border-bottom: none;
          }
        }
        
      }
    }
  }
}


.toast-contents{
  background-color: $BASE_WHITE;
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  border: 1px solid #DDD;
  border-radius: 0.5rem;
  animation: zoomIn 0.2s;
  .toast-heading{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.25rem 2rem 0.25rem 0.5rem;
    border-bottom: 1px solid #DDD;
  }
  .toast-close{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    padding: 0.125rem;
    background-color: #EEE;
    border-radius: 50%;
    display: flex;
    align-items: center;
    svg{
      width: 20px;
      height: 20px;
    }
  }
  .toast-body{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0.75rem 2rem 0.75rem 1rem;
  }
  &.is-error{
    background-color: $DANGER;
    color: #FFF;
    .toast-close{
      background-color: #FFF;
      color: $DANGER;
    }
  }
  &.is-success{
    background-color: $SUCCESS;
    color: #FFF;
    .toast-close{
      background-color: #FFF;
      color: $SUCCESS;
    }
  }
  }


.comment-contents{
  background-color: $SECONDARY;
  color: #FFF;
  position: absolute;
  bottom: -2%;
  left: 50%;
  margin-left: -120px;
  width: 240px;
  border-radius: 0.5rem;
  z-index: 10;
  animation: zoomIn 0.3s;
  @include sp-s {
    bottom: -0.375rem;
  }
  @include sp-m {
    bottom: 0.5rem;
  }
  @include sp-xs {
    bottom: 0;
  }
  @include sp-xss {
    bottom: 2%;
  }
  @include sp-xsss {
    bottom: 0;
  }
  @include sp-xxs {
    bottom: 0;
  }
  &::after{
    content: "";
    position: absolute;
    right: 0;
    top: -0.375rem;
    left: 0;
    width: 0;
    height: 0;
    margin: auto;
    border-style: solid;
    border-color: transparent transparent $SECONDARY transparent;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    @include sp-xs {
      border-width: 0 0.375rem 0.375rem 0.375rem;
    }
  }
  .comment-body{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    white-space: pre-wrap;
    @include sp-xs {
      font-size: $size-text-s;
      padding: 0.75rem 0.25rem;
    }
  }
}




.modal-wrap .modal-contents .modal-footer .app-box-button.loading-button {
  transition-duration: 1s;
	transition-delay: 0.5s;
  .button-wrap {
    padding: 0.5rem 0.75rem;
    transition: .2s;
    justify-content: center;
    transition-duration: 1s;
	  transition-delay: 0.5s;
    @include sp-m {
      padding: 0.75rem 0.75rem;
    }
    &:last-child {
      background-color: $PRIMARY;
      border-color: $PRIMARY;
      margin-right: 0;
      flex-wrap: nowrap;
    }
    &.is-disabled {
      opacity: 0.1;
      transition: .2s;
    }
    .button-label{
      width: 100%;
      line-height: 1.2;
      font-size: $size-text-s;
      @include sp-m {
        margin: 0;
        line-height: 1.4;
        font-size: $size-text;
      }
    }
  }
}

.button-label-icon{
  line-height: 1;
  margin-right: 0.25rem;
}

.loading-bar-wrap{
  position: fixed;
  top: 0;
  left: 0;
  height: 6px;
  width: 100%;
  z-index: 100;
  background-color: #EEE;
  display: flex;
  align-items: center;
  .loading-race-by {
    --uib-size: 100%;
    --uib-speed: 2.8s;
    --uib-color: black;
    --uib-line-weight: 6px;
  
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--uib-line-weight);
    width: var(--uib-size);
    border-radius: calc(var(--uib-line-weight) / 2);
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  
  .loading-race-by::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $PRIMARY;
    background-color: #D9D9D9;
    opacity: 0.5;
  }
  
  .loading-race-by::after {
    content: '';
    height: 100%;
    width: 100%;
    border-radius: calc(var(--uib-line-weight) / 2);
    animation: loadingRaceBy var(--uib-speed) ease-in-out infinite;
    transform: translateX(-110%);
    background-color: $PRIMARY;
  }
  
  @keyframes loadingRaceBy {
    0% {
      transform: translateX(-105%);
    }
    100% {
      transform: translateX(101%);
    }
  }
}
.loading-ring {
  --uib-size: 24px;
  --uib-speed: 2s;
  --uib-color: black;
  
  height: var(--uib-size);
  width: var(--uib-size);
  vertical-align: middle;
  transform-origin: center;
  animation: loadingRingRotate var(--uib-speed) linear infinite;
}

.loading-ring circle {
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: loadingRingStretch calc(var(--uib-speed) * 0.75) ease-in-out infinite;
}

@keyframes loadingRingRotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loadingRingStretch {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -124px;
  }
}


.is-setting-app {
  .loading-bar-wrap{
    position: absolute;
  }
  .loading-button-wrap{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    z-index: 3;
    .loading-button-overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.4);
      z-index: 3;
    }
  }
  .app-box-button {
    width: 90%;
    padding: 1rem 1rem;
    position: relative;
    z-index: 4;
    .button-wrap {
      padding: 0.625rem 0.75rem;
      background-color: $PRIMARY;
      border-color: $PRIMARY;
      margin-right: 0;
      flex-wrap: nowrap;
      &:last-child {
        background-color: $PRIMARY;
        border-color: $PRIMARY;
        margin-right: 0;
        flex-wrap: nowrap;
      }
      &:hover{
        opacity: 1;
      }
      &.is-loading{
        padding: 0.625rem 1.375rem 0.625rem 0.75rem;
        transition-duration: 1s;
	      transition-delay: 0.5s; 
      }
      .button-label-icon{
        margin-right: 0.5rem;
        .loading-ring {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}
