.detail-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.detail-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  li {
    margin: 8px 16px;
    list-style: none;
    border-radius: 24px;
    overflow: hidden;
    &.is-active {
      padding: 8px 24px;
      background-color: #3957C8;
      color: #FFF;
    }
    a {
      padding: 16px 24px;
      display: block;
    }
  }
}

.detail-text {
  width: 100%;
  max-width: 620px;
  text-align: center;
}