.loader-wrap{
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loader-outline {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  position: relative;
  background-color: #FFF;
}
.loader-outline::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  border: 6px dotted $PRIMARY_200;
  -webkit-animation: line-rotate-reverse 18s linear alternate infinite;
  animation: line-rotate-reverse 18s linear alternate infinite;
}
.loader-outline::before {
  content: "";
  opacity: 0.15;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 290px;
  height: 290px;
  border-radius: 50%;
  border: 8px dashed $DANGER_PALE;
  -webkit-animation: line-rotate 8s -1s linear  alternate infinite;
  animation: line-rotate 8s -1s linear  alternate infinite;
}

@-webkit-keyframes line-rotate-reverse {
  0% {
    -webkit-transform: rotate(0deg) scale(0.98);
    transform: rotate(0deg) scale(0.98);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1.05);
    transform: rotate(360deg) scale(1.05);
  }
}

@keyframes line-rotate-reverse {
  0% {
    -webkit-transform: rotate(0deg) scale(0.98);
    transform: rotate(0deg) scale(0.98);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1.05);
    transform: rotate(360deg) scale(1.05);
  }
}

@-webkit-keyframes line-rotate {
  0% {
    
    -webkit-transform: rotate(360deg) scale(0.98);
    transform: rotate(360deg) scale(0.98);
  }
  100% {
    -webkit-transform: rotate(0deg) scale(1.1);
    transform: rotate(0deg) scale(1.1);
  }
}

@keyframes line-rotate {
  0% {
    
    -webkit-transform: rotate(360deg) scale(0.98);
    transform: rotate(360deg) scale(0.98);
  }
  100% {
    -webkit-transform: rotate(0deg) scale(1.1);
    transform: rotate(0deg) scale(1.1);
  }
}

.loader-body{
  width: 100%;
  height: 100%;
  padding: 3rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .loader-image {
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 75%;
      height: 75%;
      margin: 0 auto;
    }
  }
  .loader-text{
    width: 100%;
    text-align: center;
    color:$SECONDARY;
  }
}


@-webkit-keyframes star-small-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes star-small-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}
