
.app-box-body {
  .app-chart-box {
    position: relative;
  
    .chart-box {
      position: relative;
      border-radius: 50%;
      margin-right: auto;
	    margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 11%;

      .chart-box-inner {
        background-color: $BASE_WHITE;
        border-radius: 50%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        width: 98%;
        height: 98%;
        padding: 7% 6% 8%;
  
        .chart-title {
          width: 100%;
          font-size: 22px;
          font-size: clamp(20px, 6.875vw, 28px);
          letter-spacing: .06em;
          text-align: center;
          @include sp-m {
            letter-spacing: auto;
          }
          @include sp-xs {
            letter-spacing: -.06em;
          }
          @include sp-xsss { //w300対応
            font-size: 20px;
          }
          @include sp-xxs { //w280対応
            font-size: 18px;
          }
        }
  
        .chart-number-wrap {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          line-height: 1;
          padding: 0 0.25rem 0.5rem;
          @include sp-xs {
            padding: 0 0.25rem 0.25rem;
          }
          @include sp-xss {
            padding: 0 0 0.25rem;
          }
          .chart-number-text {
            font-weight: bold;
            font-size: $size-text-xl;
            text-align: left;
            width: 24%;
            padding-bottom: 0.25rem;
            @include sp-s {
              width: 25.5%;
              letter-spacing: -0.01em;
              padding-bottom: 0.5rem;
            }
            @include sp-m {
              width: 29%;
              padding-bottom: 1rem;
            }
            @include sp-xs {
              width: 26%;
              font-size: $size-text-l;
              letter-spacing: -0.01em;
              padding-bottom: 0.5rem;
            }
            @include sp-xxs {
              width: 24%;
              font-size: $size-text;
              letter-spacing: -0.01em;
              padding-bottom: 0.5rem;
            }
          }

          .chart-number-count {
            width: 80%;
            font-weight: bold;
            font-size: $size-heading-0;
            text-align: center;
            @include sp-s {
              width: 76%;
              letter-spacing: -0.03em;
            }
            @include sp-m {
              font-size: $size-heading-00;
              letter-spacing: -0.01em;
            }
            @include sp-xs {
              width: 77%;
              font-size: $size-heading-0;
              letter-spacing: -0.03em;
            }
            @include sp-xss {
              width: 77%;
              font-size: $size-heading-01;
              letter-spacing: -0.03em;
            }
            @include sp-xxs {
              width: 77%;
              font-size: $size-heading-01;
              letter-spacing: -0.03em;
            }
            @include sp-xxxs { //w260対応
              width: 70%;
              font-size: $size-heading-1;
              letter-spacing: -0.03em;
            }
          }
        
          .chart-number-day {
            width: 20%;
            font-weight: bold;
            font-size: $size-text-xxl;
            text-align: right;
            padding-bottom: 0.25rem;
            @include sp-s {
              width: 21%;
              padding-bottom: 0.5rem;
            }
            @include sp-m {
              width: 24%;
              padding-bottom: 1rem;
            }
            @include sp-xs {
              font-size: $size-text-l;
              width: 20%;
              padding-bottom: 0.5rem;
              padding-bottom: 0.5rem;
            }
            @include sp-xxs {
              font-size: $size-text;
              width: 18%;
              padding-bottom: 0.5rem;
              padding-bottom: 0.5rem;
            }
          }
        }

        .chart-date-wrap {
          padding: 4px 12px;
          display: flex;
          justify-content: center;
          text-align: center;
          flex-wrap: wrap;
          @include sp-m {
            padding: 0 12px;
          }
          li{
            width: 100%;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom:0.25rem;
            &:last-child{
              border: none;
              margin-right: 0;
            }
            .chart-date-label{
              position: relative;
              font-size: $size-text-xxs;
              padding: 0.375rem 0.25rem;
              text-align: center;
              border-radius: 50%;
              margin-right: 0.5rem;
              @include sp-xxs {
                padding: 0.25rem 0.125rem;
              }
            }
            .chart-date{
              min-width: 72px;
              text-align: left;
              @include sp-m {
                font-size: $size-text-l;
              }
              @include sp-xxs {
                min-width: 60px;
                font-size: $size-text-s;
              }
            }
            &.is-start-label{
              .chart-date-label{
                background-color: $PRIMARY;
                &:after{
                  color: $PRIMARY;
                }
                
              }
            }
            &.is-end-label{
              .chart-date-label{
                background-color: $SECONDARY;
                color: $BASE_WHITE;
                &:after{
                  color: $SECONDARY;
                }
              }
            }
          }
        }
      }
    }
  }
}

.app-chart-box{
  width: 320px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  position: relative;
  background-color: rgba(239, 203, 68, 0.4);
  border-radius: 50%;
  @include sp-xs {//w360対応
    width: 316px;
    height: 316px;
  }
  @include sp-m {//w390-480対応
    width: 360px;
    height: 360px;
  }
  @include sp-xss { //w320対応
    width: 300px;
    height: 300px;
  }
  @include sp-xsss { //w300対応
    width: 280px;
    height: 280px;
  }
  @include sp-xxs { //w280対応
    width: 260px;
    height: 260px;
  }
  @include sp-xxxs { //w260対応
    width: 240px;
    height: 240px;
  }
  .chart-box-canvas{
    position: absolute;
    top: -5.5px;
    z-index: 1;
    @include sp-s {
      top: -5.5px;
    }
    @include sp-m {
      top: -5.5px;
    }
    @include sp-xs {
      top: -5.5px;
    }
    @include sp-xxs {
      top: -5.2px;
    }
  }
  .chart-box-inner{
    width: 98%;
    height: 98%;
    background-color: #FFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border: 3px solid rgba(239, 203, 68, 0.6);
  }
  &.is-expired{
    background-color: $RED_550;
    border-radius: 50%;
    .chart-box{
      .chart-box-inner{
        border: 5px solid $RED_550;
        color: $RED_550;
        font-weight: bold;
        .chart-title{
          color: $RED_550;
          font-weight: bold;
        }
      }
      .comment-contents{
        bottom: 13%;
        left: 50%;
        @include sp-xss {
          bottom: 15%;
        }
        @include sp-xxs {
          bottom: 11%;
        }
      }
    }
    canvas.chart-box-canvas {
      opacity: 0.6;
    }
  }
}


