.modal-wrap .modal-contents .modal-body.help-body{
  padding: 0;
}
.help-wrap{
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.help-heading{
  width: 100%;
  background-color: $PRIMARY_050;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
      width: 100%;
      @include sp-s {
        width: 80%;
      }
  }
  padding: 0.25rem;
  @include sp-s {
    padding: 0.125rem;
  }
}
