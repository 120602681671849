.calendar-wrap{
  width: 100%;
  min-height: 40px;
  padding: 0.5rem 0;
  @include sp-m {
    padding: 0 0.625rem 0.125rem;
  }
  @include sp-s {
    padding: 0 0.125rem 0.125rem;
  }
  @include sp-xs {
    padding: 0 0.75rem 0.125rem;
  }
  @include sp-xxs {
    padding: 0 0 0.125rem;
  }
  .calendar-heading{
    font-size: $size-text-xl;
    padding: 0.25rem 0 0;
    text-align: center;
    @include sp-m {
      padding: 0 0 0.5rem;
      font-size: $size-heading-5;
    }
    span{
      font-size: $size-text-s;
      margin: 0 0.125rem;

      @include sp-m {
        font-size: $size-text-s;
        margin: 0 0.25rem;
      }
    }
  }
  .calendar-box{
    padding: 0 0.5rem;
    position: relative;
    min-height: 268px;
    @include sp-s {
      min-height: 303px;
    }
    .calendar-box-line{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top:0.375rem;
      @include sp-m {
        margin-top:0.125rem;
      }
      @include sp-s {
        margin-top:0.125rem;
      }
      &.is-weekday-line{
        margin-top:0rem;
        margin-bottom: 0.5rem;
        @include sp-s {
          margin-top: 0.5rem;
          margin-bottom: 0.75rem;
        }
        li{
          font-size: $size-text-s;
          border-bottom: 1px solid $SECONDARY;
          border-radius: 0;
          position: relative;
          height: 1.6rem;
          margin: 0.25rem 0.125rem 0.75rem;
          &:nth-child(1) {
            color: $RED_400;
            border-bottom-color: $RED_400;
          }
          &:nth-child(7) {
            color: $SECONDARY_350;
            border-bottom-color: $SECONDARY_350;
          }
          @include sp-m {
            margin: 0.25rem 0.125rem 0.25rem;
          }
          @include sp-s {
            margin: 0.25rem 0.125rem 0.25rem;
          }
        }
      }
      li{
        width: 50%;
        width: 2.1rem;
        height: 2.1rem;
        margin: 0.125rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $BASE_WHITE;
        border: 1px solid $BASE_WHITE;
        border-radius: 50%;
        font-size: $size-text-xl;
        line-height: 1;
        letter-spacing: -0.02em;
        @include sp-m {
          width: 2.1rem;
          height: 2.0rem;
          margin: 0.125rem;
          font-size: $size-text-xxl;
        }
        @include sp-s {
          width: 2.1rem;
          height: 2.0rem;
          margin: 0.25rem 0.125rem;
          font-size: $size-text-xl;
        }
        @include sp-xxs {
          width: 1.9rem;
          height: 1.8rem;
          margin: 0.25rem 0.125rem;
          font-size: $size-text-xl;
        }
        &:before{
          position: absolute;
          width: 3.2rem;
          top: -0.68rem;
            right: -0.56rem;
          color: $SECONDARY;
          font-weight: bold;
          font-size: $size-text-xxs;
          @include sp-s {
            font-size: $size-text-xxs;
            top: -0.68rem;
            right: -0.56rem;
          }
          @include sp-m {
            font-size: $size-text-xxs;
            top: -0.68rem;
            right: -0.56rem;
          }
        }
        &:nth-child(1) {
          color: $RED_400;
        }
        &:nth-child(7) {
          color: $SECONDARY_350;
        }
        &.is-disabled{
          opacity: .5;
        }
        &.is-holiday{
          color: $RED_400;
        }
        &.is-startDate{
          background-color: $PRIMARY;
          border: 1px solid $PRIMARY;
          color: $SECONDARY;
          position: relative;
          font-weight: bold;
          &:before{
            content: '開始';
            color: $SECONDARY;
          }
        }
        &.is-renewDate{
          background-color: $SECONDARY;
          border: 1px solid $SECONDARY;
          color: $BASE_WHITE;
          position: relative;
          &:before{
            content: '交換';
          }
        }
        &.is-noticeDate{
          border: 1px solid $SECONDARY;
          position: relative;
          &:after{
            position: absolute;
            content: '';
            top: -2.5px;
            right: -3px;
            width: 10px;
            height: 10px;
            background-color: $RED_400;
            border-radius: 50%;
            @include sp-m {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
    .calendar-control-wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .calendar-prev,
      .calendar-next{
        position: absolute;
        top: 54%;
        cursor: pointer;
        @include sp-m {
          top: 52%;
        }
        &.is-disabled{
          cursor: unset;
          opacity: 0.5;
        }
      }
      .calendar-prev{
        left: -6%;
        @include sp-m {
          left: -6.8%;
        }
      }
      .calendar-next{ 
        right: -6%;
        @include sp-m {
          right: -6.8%;
        }
      }
      .calendar-control-icon{
        width: 1.8rem;
        height: 1.8rem;
        background-color: $BASE_WHITE;
        padding: 0.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $GRAY_100;
        border-radius: 50%;
        @include sp-m {
          width: 2rem;
          height: 2rem;
        }
      }
      .calendar-batch-wrap{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        svg{
          width: 10px;
          height: 10px;
        }
        .batch-startDot{
          color: $PRIMARY;
        }
        .batch-renewDot{
          color: $SECONDARY;
        }
        .batch-noticeDot{
          color: $RED_400;
        }
      }
    }
  }
}