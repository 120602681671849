$mq-260: 260px;
$mq-280: 281px;
$mq-300: 301px;
$mq-320: 321px;
$mq-360: 374px;
$mq-375: 376px;
$mq-390: 389px;
$mq-414: 413px;
$mq-480: 481px;
$mq-986: 986px;
@mixin sp-xxxs {
  @media only screen and (max-width:#{$mq-260}) {
    @content;
  }
}
@mixin sp-xxs {
  @media only screen and (max-width: #{$mq-280}) {
    @content;
  }
}
@mixin sp-xsss {
  @media only screen and (max-width: #{$mq-300}) {
    @content;
  }
}
@mixin sp-xss {
  @media only screen and (max-width: #{$mq-320}) {
    @content;
  }
}
@mixin sp-xs {
  @media only screen and (max-width: #{$mq-360}) {
    @content;
  }
}
@mixin sp-s {
  @media only screen and (max-width: #{$mq-375}) {
    @content;
  }
}
@mixin sp-m {
  @media only screen and (min-width:#{$mq-390}) and (max-width: #{$mq-480}) {
    @content;
  }
}
@mixin sp-l {
  @media only screen and (min-width: #{$mq-414}) {
    @content;
  }
}
